<template>
  <BaseDetailSimple
    :input-attributes="inputAttributes"
    :leave-guard="leaveGuard"
    :query-values="queryValues"
    :query-selects="querySelects"
    entity-type="province"
    locale-section="pages.provinces"
    field-section="provinces"
  />
</template>

<script>
import BaseDetailPage from "@/components/BaseDetailPage";

export default {
  name: "ProvinceDetailPage",
  extends: BaseDetailPage,
  data() {
    return {
      queryValues: /* GraphQL */ `
        _name: province
        province
        country {
          id
        }
      `,
      querySelects: /* GraphQL */ `
        country: countries {
          id
          country
          sv
          en
        }
      `,
      inputAttributes: {
        province: {
          required: true
        },
        country: {
          required: true
        }
      }
    };
  }
};
</script>
